import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '@components';

const NotFoundPageContainer = () => (
  <>
    <SEO title="Page Note Found" />
  </>
);

NotFoundPageContainer.propTypes = {
  notFoundPage: PropTypes.object.isRequired,
  rest: PropTypes.object
};

export default NotFoundPageContainer;
