import React from 'react';
import PropTypes from 'prop-types';
import { SEO, Layout, Hero, IconPanel, ContentPodGroup } from '@components';

const HomepageContainer = ({ homepage, hero, iconPanel, contentPodGroup, ...rest }) => (
  <Layout {...rest}>
    <SEO title={homepage.pageTitle} />
    <Hero {...hero} />
    <IconPanel {...iconPanel} />
    <ContentPodGroup {...contentPodGroup} />
  </Layout>
);

HomepageContainer.propTypes = {
  homepage: PropTypes.object.isRequired,
  hero: PropTypes.object.isRequired,
  rest: PropTypes.object
};

export default HomepageContainer;
