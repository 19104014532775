import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Layout, InteriorHero, ImageCardGrid } from '@components';

const TeamPageContainer = ({ data }) => {
  const pageTitle = data.contentfulTeamPages.title;
  const description = data.contentfulTeamPages.description.json;
  const teamMembers = data.contentfulTeamPages.teamMembers.map(
    ({
      name,
      title,
      linkedIn,
      image: {
        fixed: { src }
      }
    }) => ({
      linkedIn,
      image: src,
      title: name,
      description: title
    })
  );

  return (
    <Layout {...data}>
      <SEO title={pageTitle} />
      <InteriorHero
        title={pageTitle}
        description={description}
        className="interior-hero--green"
      />
      <div className="page-wrapper">
        <ImageCardGrid items={teamMembers} />
      </div>
    </Layout>
  );
};

export default TeamPageContainer;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTeamPages(slug: { eq: $slug }) {
      title
      description {
        json
      }
      teamMembers {
        name
        title
        linkedIn
        image {
          fixed {
            src
          }
        }
      }
    }
  }
`;
