import React from 'react';
import { HomepageContainer } from '@containers';
import { useSiteSettingsData, useHomepageData } from '@hooks';

const IndexPage = () => {

  const siteSettingsData = useSiteSettingsData();
  const homepageData = useHomepageData();

  const mergedData = {
    ...siteSettingsData,
    ...homepageData
  };

  return (
    <HomepageContainer {...mergedData} />
  );
};

export default IndexPage;