import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Layout, InteriorHero, ImageCardGrid } from '@components';

const ServicesPageContainer = ({ data }) => {
  const pageTitle = data.contentfulSpecialtyServicesPages.title;
  const description =
    data.contentfulSpecialtyServicesPages.description.description;
  const items = data.contentfulSpecialtyServicesPages.services.map(item => ({
    ...item,
    description: item.description.description,
    image: item.image.fixed.src
  }));

  return (
    <Layout {...data}>
      <SEO title={pageTitle} />
      <InteriorHero
        title={pageTitle}
        description={description}
        bgColor="green"
      />
      <div className="page-wrapper">
        <ImageCardGrid className="image-card-grid--two-wide" items={items} />
      </div>
    </Layout>
  );
};

export default ServicesPageContainer;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulSpecialtyServicesPages(slug: { eq: $slug }) {
      slug
      title
      description {
        description
      }
      services {
        title
        description {
          description
        }
        iconId
        icon {
          file {
            url
          }
        }
        image {
          fixed {
            src
          }
        }
      }
    }
  }
`;
