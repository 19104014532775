import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Layout, InteriorHero, EventsCalendar } from '@components';

const EventsPageContainer = ({ data }) => {
  const pageTitle = data.contentfulNewsEventsPages.title;
  const description = data.contentfulNewsEventsPages.description.description;
  const events = data.contentfulNewsEventsPages.events.map(event => ({
    ...event,
    url: `/${event.slug}`
  }));

  return (
    <Layout {...data}>
      <SEO title={pageTitle} />
      <InteriorHero
        title={pageTitle}
        description={description}
        bgColor="orange"
      />
      <div className="page-wrapper">
        <EventsCalendar events={events} />
      </div>
    </Layout>
  );
};

export default EventsPageContainer;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulNewsEventsPages(slug: { eq: $slug }) {
      slug
      title
      description {
        description
      }
      events {
        slug
        title
        date(formatString: "MM/DD/YY")
      }
    }
  }
`;
