import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Layout, InteriorHero, DocumentList } from '@components';

const DocumentPageContainer = ({ data }) => {
  const pageTitle = data.contentfulWhitePapersPages.title;
  const interiorHero = {
    subtitle: 'Featured White Paper',
    title: data.contentfulWhitePapersPages.featuredItem.title,
    description:
      data.contentfulWhitePapersPages.featuredItem.description.description,
    button: {
      classNames: 'button--secondary',
      isLink: true,
      text: 'Read More',
      url: `/${data.contentfulWhitePapersPages.featuredItem.file.file.url}`
    }
  };
  const documentList = {
    title: pageTitle,
    categories: [
      {
        id: 'case-studies',
        pluralLabel: 'Case Studies',
        singularLabel: 'Case Study'
      },
      {
        id: 'whitepapers',
        pluralLabel: 'Whitepapers',
        singularLabel: 'Whitepaper'
      }
    ],
    items: data.contentfulWhitePapersPages.items.map(
      ({ title, file: { file: url } }) => {
        return {
          title,
          url: url.url
        };
      }
    )
  };

  return (
    <Layout {...data}>
      <SEO title={pageTitle} />
      <InteriorHero {...interiorHero} bgColor="green" />
      <div className="page-wrapper">
        <DocumentList {...documentList} />
      </div>
    </Layout>
  );
};

export default DocumentPageContainer;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulWhitePapersPages(slug: { eq: $slug }) {
      slug
      title
      featuredItem {
        description {
          description
        }
        title
        slug
        file {
          file {
            url
          }
        }
      }
      items {
        ... on ContentfulWhitePapers {
          title
          file {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
