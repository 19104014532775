import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Layout, ContentBlock, InteriorHero } from '@components';

const ContentPageContainer = ({ data }) => {
  const pageTitle = data.contentfulContentPages.title;
  const description = data.contentfulContentPages.description.description;
  const {
    childContentfulContentPagesContentRichTextNode,
    featuredImage: {
      file: { url },
      title
    }
  } = data.contentfulContentPages;
  const html =
    childContentfulContentPagesContentRichTextNode?.childContentfulRichText
      ?.html;
  const heroBackgroundColor = data.contentfulContentPages.heroBackgroundColor;

  return (
    <Layout {...data}>
      <SEO title={pageTitle} />
      <InteriorHero
        title={pageTitle}
        description={description}
        bgColor={heroBackgroundColor}
      />
      <article className="interior-content-wrapper">
        <div className="page-wrapper">
          <ContentBlock
            image={{
              src: url,
              alt: title
            }}
            content={html}
          />
        </div>
      </article>
    </Layout>
  );
};
export default ContentPageContainer;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulContentPages(slug: { eq: $slug }) {
      slug
      title
      description {
        description
      }
      childContentfulContentPagesContentRichTextNode {
        childContentfulRichText {
          html
        }
      }
      featuredImage {
        file {
          url
        }
        title
      }
      heroBackgroundColor
    }
  }
`;
